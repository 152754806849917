import axios from "@/plugins/axios";

export default {
  async getSettings() {
    return (await axios.get("/invitation")).data;
  },
  async postSettings(data) {
    return (await axios.post("/invitation", data)).data;
  },
};
