import logsHTTP from "@/api/logs";

const state = {
  logs: null,
};
const mutations = {
  setLogs(state, payload) {
    state.logs = payload;
  },
};
const actions = {
  async getLogs({ commit }) {
    try {
      const res = await logsHTTP.getLogs();
      commit("setLogs", res);
    } catch (e) {
      throw e;
    }
  },
};
const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
