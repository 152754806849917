import axios from "@/plugins/axios";

export default {
  async getAuth(params) {
    try {
      return (await axios.get("/auth", { params })).data;
    } catch (error) {
      if (error?.response?.status === 401) {
        window.location.replace("/401");
      }
    }
  },
  async postCreditsUser(data) {
    return (await axios.post("/feedback-api/auth", data)).data;
  },

  async getCreditsUser() {
    return (await axios.get("/feedback-api/auth")).data;
  },
};
