<template>
  <div v-if="errorMessage" class="text-center">
    <v-snackbar
      class="justify-end"
      color="red"
      :value="errorMessage"
      :timeout="4000"
    >
      {{ errorMessage }}
      <template #action="{ attrs }">
        <v-btn
          color="black"
          text
          v-bind="attrs"
          @click="() => setErrorMessage(null)"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["errorMessage"]),
  },
  methods: {
    ...mapMutations(["setErrorMessage"]),
  },
};
</script>
