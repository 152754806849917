import authHTTP from "@/api/auth";
const state = {
  client_id: "",
  client_secret: "",
  isDisabledNextPage: true,
  sessionToken: null,
  shopifyApp: null,
  params: null,
};
const mutations = {
  setToken(state, token) {
    state.sessionToken = token;
  },
  setShopifyApp(state, shopifyApp) {
    state.shopifyApp = shopifyApp;
  },
  setClientId(state, client_id) {
    state.client_id = client_id;
  },
  setClientSecret(state, client_secret) {
    state.client_secret = client_secret;
  },
  setDisabledNextPage(state, bool) {
    state.isDisabledNextPage = bool;
  },
  setParams(state, params) {
    console.log("~ params", params);
    state.params = params;
  },
};
const actions = {
  fetchAuth: async ({ commit }, params) => {
    const res = await authHTTP.getAuth(params);
    if (!res?.token) {
      throw "auth failed";
    }
    commit("setToken", res?.token);
    return res;
  },
  postCreditsUser: async ({ commit, state: { client_id, client_secret } }) => {
    const res = await authHTTP.postCreditsUser({
      client_id,
      client_secret,
    });
  },

  getCreditsUser: async ({ commit }, params) => {
    const res = await authHTTP.getCreditsUser(params);
    if (Array.isArray(res)) {
      throw "";
    }
    commit("setClientId", res?.client_id || null);
    commit("setClientSecret", res?.client_secret || null);
    return res;
  },
};
const getters = {
  isAuth(state) {
    return !!sessionStorage.getItem("shopify_token");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
