import Vue from "vue";
import Vuex from "vuex";

import Auth from "./modules/Auth";
import Widgets from "./modules/Widgets";
import Settings from "./modules/Settings";
import Logs from "./modules/Logs";
import Pages from "./modules/Pages";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: { loading: true, errorMessage: null },
  getters: {},
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setErrorMessage(state, message) {
      state.errorMessage = message;
      setTimeout(() => {
        state.errorMessage = null;
      }, 4000);
    },
  },
  actions: {},
  modules: {
    Auth,
    Widgets,
    Settings,
    Logs,
    Pages,
  },
});
export default store;
