const state = {
  settings: {
    disabled: true,
  },
  widgets: {
    disabled: true,
  },
  logs: {
    disabled: false,
  },
};
const mutations = {
  setDisabledPage(state, { page, bool }) {
    state[page].disabled = bool;
  },
};
const actions = {};
const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
