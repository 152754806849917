import axios from "@/plugins/axios";

export default {
  async getWidgets() {
    return (await axios.get("/widgets")).data;
  },
  async postWidgets(data) {
    return (await axios.post("/widgets", data)).data;
  },
};
