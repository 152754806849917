import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
Vue.use(VueRouter);

const routes = [
  {
    path: "",
    name: "Authentication",
    component: () => import("@/views/Authentication/Index.vue"),
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("@/views/Settings/Index.vue"),
  },
  {
    path: "/widgets",
    name: "Widgets",
    component: () => import("@/views/Widgets/Index.vue"),
  },
  {
    path: "/logs",
    name: "Logs",
    component: () => import("@/views/Logs/Index.vue"),
  },
  {
    path: "/401",
    name: "401",
    component: () => import("@/views/401.vue"),
    meta: {
      error: true,
    },
  },
  {
    path: "*",
    name: "Not Found",
    component: () => import("@/views/404.vue"),
    meta: {
      error: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
