<template>
  <v-tabs fixed-tabs show-arrows>
    <v-tabs-slider color="white"></v-tabs-slider>
    <v-tab :to="{ name: 'Authentication', query: params }"
      >Authentication</v-tab
    >
    <v-tab
      :disabled="settings.disabled"
      :to="{ name: 'Settings', query: params }"
    >
      Invitation settings
    </v-tab>
    <v-tab :disabled="widgets.disabled" :to="{ name: 'Widgets', query: params }"
      >Widgets</v-tab
    >
    <v-tab :disabled="logs.disabled" :to="{ name: 'Logs', query: params }"
      >Logs</v-tab
    >
  </v-tabs>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "NavBar",

  data: () => {
    return {};
  },
  computed: {
    ...mapState("Pages", ["settings", "widgets", "logs"]),
    ...mapState("Auth", ["params"]),
  },
};
</script>
