<template>
  <v-app :class="changeTheme">
    <v-app-bar v-if="!isErrorPage" app>
      <NavBar />
      <ProgressLine />
    </v-app-bar>
    <v-main class="v-main-static-color">
      <v-container class="wrap mt-10">
        <div v-if="!isPageLoading" class="wrap">
          <transition name="fade" mode="out-in">
            <router-view> </router-view>
          </transition>
        </div>
      </v-container>
      <SuccessAlert />
    </v-main>
    <Footer v-if="!isErrorPage" />
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar/Index.vue";
import ProgressLine from "@/components/ProgressLine.vue";
import SuccessAlert from "@/components/Alert/SuccessAlert.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import Footer from "@/components/Footer";

import { mapGetters } from "vuex";
export default {
  name: "App",
  components: { NavBar, ProgressLine, Footer, SuccessAlert },
  data: () => ({
    theme: false,
    isPageLoading: true,
    //
  }),
  computed: {
    ...mapState("Auth", ["params"]),
    ...mapGetters("Auth", ["isAuth"]),
    isErrorPage() {
      return this.$route.meta.error;
    },
    changeTheme() {
      return this.isErrorPage ? "error-theme" : "feedbackcompany-theme";
    },
  },
  async mounted() {
    try {
      this.isPageLoading = true;
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      this.setParams(params);
      if (!this.$route.meta.error) {
        if (!this.params?.hmac) {
          this.$router.push("/404");
        } else {
          await this.fetchAuth(this.params);
        }
      }
    } catch (e) {
      console.log(e);
    }
    this.isPageLoading = false;
  },
  methods: {
    ...mapActions("Auth", ["fetchAuth"]),
    ...mapMutations("Auth", ["setParams"]),
  },
};
</script>

<style lang="scss">
@import "@/assets/feedbackcompany.scss";

.title-color {
  color: #aeaeb2;
}
.wrap {
  max-width: 1100px;
  width: 100%;
  .wrap:only-child {
    margin: 0 auto;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
