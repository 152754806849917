import axios from "@/plugins/axios";

export default {
  async getLogs() {
    return (await axios.get("/logs")).data;
  },
  // async postLogs(data) {
  //   return (await axios.post("/logs", data)).data;
  // },
};
