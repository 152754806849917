import Axios from "axios";
import store from "@/store";

const baseURL = process.env.VUE_APP_BACKEND_URL;
const axios = Axios.create({ baseURL });

axios.interceptors.request.use(async function (config) {
  const token = `Bearer ${store.state["Auth"]?.sessionToken}`;
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
}, null);
axios.interceptors.response.use(
  (response) => {
    if (response.data.redirect) {
      window.location.replace(response.data.redirect);
    }
    return response;
  },
  async (error) => {
    if (error?.response?.status === 401) {
      window.location.replace("/401");
    }
    store.commit("setErrorMessage", error.response?.data?.error, {
      root: true,
    });
    throw error;
  }
);
export default axios;
