import settingsHTTP from "@/api/settings";

const state = {
  settings: {
    trigger: null,
    delay_unit: null,
    delay_amount: null,
  },
  isDisabledNextPage: false,
};
const mutations = {
  setSettings(state, payload) {
    state.settings = payload;
  },
  setDisabledNextPage(state, bool) {
    state.isDisabledNextPage = bool;
  },
};
const actions = {
  async getSettings({ commit }) {
    const res = await settingsHTTP.getSettings();
    commit("setSettings", res);
  },
  async postSettings({ commit, state }) {
    await settingsHTTP.postSettings(state.settings);
  },
};
const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
