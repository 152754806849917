<template>
  <v-footer v-if="!isErrorPage" color="white">
    <v-img
      max-width="125"
      src="@/assets/feedbackcompany-logo.svg"
      class="footer-img"
    />
    <div class="ma-auto">
      <v-btn
        v-for="btn in footerBtn"
        :key="btn.href"
        :href="btn.href"
        text
        color="#192454"
        target="_blank"
      >
        {{ btn.label }}
      </v-btn>
    </div>
  </v-footer>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    footerBtn: [
      {
        label: "Privacy Policy",
        href: "https://www.feedbackcompany.com/privacy-statement/",
      },
      {
        label: "Terms of use",
        href: "https://www.feedbackcompany.com/terms-and-conditions/",
      },
      {
        label: "FAQ",
        // eslint-disable-next-line vue/max-len
        href: "https://intercom.help/feedbackcompany/nl/collections/2479097-review-portal-appreciation",
      },
      {
        label: "Support page",
        href: "https://www.feedbackcompany.com/contact/",
      },
    ],
  }),
  computed: {
    isErrorPage() {
      return this.$route.name === "Not Found";
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/feedbackcompany.scss";
.footer-img {
  position: absolute !important;
}
@media screen and (max-width: 900px) {
  .footer-img {
    position: relative !important;
  }
}
</style>
